import React from 'react';
import { IntegrationCarousel, LangHorizontalTab } from 'components';

const LanguageIntegrationBlock = ({ data }) => {
  const {
    primary: { block_style: blockStyle, title1: title, description },
    items,
  } = data;

  return (
    <>
      {blockStyle === 'Carousel' ? (
        <IntegrationCarousel title={title.text} desc={description} links={items} />
      ) : (
        <LangHorizontalTab title={title.text} links={items} />
      )}
    </>
  );
};

export default LanguageIntegrationBlock;
